import React, { useEffect } from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import RegistrationForm from '@widgets/RegistrationForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'
import Poster from '@widgets/Poster'
import AdHorizontal from '@widgets/AdHorizontal'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Card as CardComponent } from 'theme-ui'
import { PostBody } from '@widgets/Post'
import { isLoggined } from '@helpers-blog'

const Live = () => {
  useEffect(() => {
    if (isLoggined()) {
      navigate('/plus')
    }
  })
  
  const data = useStaticQuery(liveQuery22)
  const live = data.allSanityLive.nodes[0]
  const specific = data.allArticle.edges[0].node
  console.log(specific)
  
  return (
    <>
      <Seo title='Canlı Yayına Bağlanın' />
      <Divider />
      <Stack>
        <PageTitle header={live.title} subheader="Canlı yayına bağlanmak için aşağıdaki formu doldurup gönder tuşuna basın. Cepten izlemek ve soru sormak için mobil uygulamayı indirebilirsiniz" />
      </Stack>
      <Stack>
        <Main>
        <Divider space={2} />
          <AdHorizontal no={3} />{' '}
          <Divider space={2} />
          <RegistrationForm />
          <Divider space={2} />
            <CardComponent variant='paper'>
              <PostBody {...specific} />
            </CardComponent>
        </Main>
        <Sidebar>
          <Poster no={1} />
          <Divider space={2} />
          <Poster no={3} />
          <Divider space={2} />
          <Poster no={4} />
          <Divider space={3} />
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  )
}

const liveQuery22 = graphql`
  query liveQuery22 {
    allSanityLive {
      nodes {
        title
        subTitle
        videoLink
      }
    }
    allArticle(filter: { title: { eq: "Live Embed 2" } }) {
      edges {
        node {
          body
        }
      }
    }
  }
`
export default Live